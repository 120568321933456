<template>
  <div>
    <h1 class="heading">Вход</h1>

    <div class="sub-menu-links d-flex align-items-center mb-5">
      <router-link to="/registration/" class="ml-0 menu-link"
        >Регистрация
        <span class="material-icons right-arrow-icon"> east </span>
      </router-link>
    </div>
    <h6>Введите данные для входа</h6>
    <div class="custom-input-group">
      <div class="row">
        <div class="col-lg-6 col-md-8 col-sm-10 col-12">
          <custom-input-with-label
            :type="customInputEmail.type"
            :name="customInputEmail.name"
            :value="user.email"
            :text="customInputEmail.text"
            v-on:change="setUserData('email', $event)"
          />
          <custom-input-password
            :name="customInputPassword.name"
            :value="user.password"
            :text="customInputPassword.text"
            v-on:change="setUserData('password', $event)"
          />

          <div v-if="errorMessage.length > 0" class="mb-2">
            <notify-danger>{{errorMessage}}</notify-danger>
          </div>
          
          <main-button @click="login" :isActive="SubmitIsActive" :buttonDisabled="buttonDisabled"
                >Войти
          </main-button>
          
          <div class="restore-link">
            <router-link to="/restore/" class="ml-0 restore-link"
              >Забыли пароль?</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      buttonDisabled: false,
      SubmitIsActive: false,
      user: {
        email: "",
        password: "",
        //'email': "1205@sntinvest.ru",
        //'password': "zkBJXtpeOa",
      },
      customInputEmail: {
        type: "email",
        name: "email",
        placeholder: "",
        text: "E-mail",
      },
      customInputPassword: {
        type: "password",
        name: "password",
        placeholder: "",
        text: "Пароль",
      },
      errorMessage: "",
    };
  },
  methods: {
    setUserData(name, value) {
      if (value instanceof Event) return;

      this.user[name] = value;
    },
    login() {
      this.SubmitIsActive = true
      this.$store
        .dispatch("loginUsers", this.user)
        .then(() => {
          this.$router.push("/");

          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((reason) => {
          //console.log("reason", reason.response.data.message)
          if (reason.response.data.errors) {
            this.errorMessage = reason.response.data.message;
          }
        })
        .finally(() => {
          this.SubmitIsActive = false
        });
    },
  },
};
</script>

<style scoped>
.right-arrow-icon {
  padding-left: 10px;
}
.menu-link {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #71747a;
  display: flex;
}
.menu-link:hover {
  text-decoration: none;
  color: #3663f2;
}
.restore-link {
  margin-top: 20px;
  /* max-width: 350px; */
  text-align: center;
}
.error-message {
  font-size: 12px;
  margin-top: 5px;
  color: #d02b20;
}
@media (max-width: 768px) {
}
</style>
