<template>
    <div><h1 class="heading">Регистрация</h1></div>
    <div class="auth-block">
        <router-link to="/login/">
            Вход
            <span class="material-icons right-arrow-icon">east</span>
        </router-link>

    </div>
    <div class="row mt-5">
        <div class="col-md-12 col-lg-6 block-center">
            <h6>Личные данные</h6>

            <custom-input-with-label
                :type="customInputPhone.type"
                :name="customInputPhone.name"
                :value="user.phone"
                :text="customInputPhone.text"
                :required="true"
                :maska="{ mask: '+7 (###) ###-##-##'}"
                :error="user.phone.length <= 0 && check"
                v-on:change="setUserData('phone', $event)"
            />
            <custom-input-with-label
                :type="customInputEmail.type"
                :name="customInputEmail.name"
                :value="user.email"
                :text="customInputEmail.text"
                :required="true"
                :error="user.email.length <= 0 && check"
                v-on:change="setUserData('email', $event)"
            />
            <custom-input-with-label
                :type="customInputSurname.type"
                :name="customInputSurname.name"
                :value="user.surname"
                :text="customInputSurname.text"
                :required="true"
                :error="user.surname.length <= 0 && check"
                v-on:change="setUserData('surname', $event)"
            />
            <custom-input-with-label
                :type="customInputName.type"
                :name="customInputName.name"
                :value="user.name"
                :text="customInputName.text"
                :required="true"
                :error="user.name.length <= 0 && check"
                v-on:change="setUserData('name', $event)"
            />
            <custom-input-with-label
                :type="customInputPatronymic.type"
                :name="customInputPatronymic.name"
                :value="user.patronymic"
                :text="customInputPatronymic.text"
                v-on:change="setUserData('patronymic', $event)"
            />
            <div class="my-3 ml-1">
                Пол:
                <b-form-radio 
                    v-model="user.gender"
                    name="gender" 
                    value="Мужской"
                >Мужской
                </b-form-radio
                >
                <b-form-radio 
                    v-model="user.gender" 
                    name="gender" 
                    value="Женский"
                >Женский
                </b-form-radio
                >
            </div>

            <custom-input-with-text
                :type="customInputDateOfBirth.type"
                :name="customInputDateOfBirth.name"
                :placeholder="customInputDateOfBirth.placeholder"
                :text="customInputDateOfBirth.text"
                :value="user.dateOfBirth"
                :required="true"
                :error="user.dateOfBirth !== undefined && user.dateOfBirth.length <= 0 && check"
                v-on:change="setUserData('dateOfBirth', $event)"
            />

        </div>
        <div class="col-md-12 col-lg-6 block-center">
            <h6>Информация о магазине</h6>

            <custom-input-with-label
                :type="customInputCompany.type"
                :name="customInputCompany.name"
                :text="customInputCompany.text"
                :value="compamy"
                :required="true"
                :error="compamy.length <= 0 && check"
                v-on:change="changeCompamy"
            />

            <template v-if="suggestions != undefined && suggestions.length > 0">
                <CustomSelectWithLabel 
                    text="ЮР. лицо"
                    :items="suggestions"
                    :required="true"
                    :error="suggestions != undefined && suggestion.length <= 0 && check"
                    v-on:change="suggestionsChange"
                    />
            </template>
            <template v-if="!isLoadSuggestions && compamy.length > 0 && suggestions != undefined && suggestions.length <= 0">
                <notify-danger class="mt-2">Компании не найдены</notify-danger>
            </template>
            <template v-if="errorsCompany.suggestions.length > 0">
                <notify-danger class="mt-2">{{errorsCompany.suggestions}}</notify-danger>
            </template>

            <template v-if="tradespots != undefined && tradespots.length > 0">
                <CustomSelectWithLabel 
                    text="Адрес магазина"
                    :items="tradespots"
                    :required="true"
                    :error="user.tradespot !== undefined && user.tradespot.length <= 0 && check"
                    v-on:change="setUserData('tradespot', $event)"
                    />
            </template>
            <template v-if="!isLoadSuggestions && suggestion.length > 0 && tradespots != undefined && tradespots.length <= 0">
                <notify-danger class="mt-2">Торговые точки не найдены</notify-danger>
            </template>
            <template v-if="errorsCompany.tradespots.length > 0">
                <notify-danger class="mt-2">{{errorsCompany.tradespots}}</notify-danger>
            </template>

            <div  v-if="isLoadSuggestions && errorsCompany.suggestions.length <= 0 && errorsCompany.tradespots.length <= 0" 
                class="position-relative" 
                style="height: 40px;"><LoadingSmall  /></div>

            <div class="file-upload mt-3" @click="chooseFiles()">
                <div class="custom-input-with-text mb-4">
                    <div class="custom-input-with-text-text">Фото магазина</div>
                    <div class="custom-input-with-text-value">
                        <div class="upload-file-field">
                            <input
                                id="fileUpload"
                                type="file"
                                name="photo"
                                @change="previewFiles"
                                ref="uploadFile"
                                hidden
                            />
                            <span class="material-icons upload-file-icon">
                upload_file
                </span>
                            <span class="file-upload-text ml-2">{{ uploadFileText }}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-lg-12 mt-5">
            <div class="result mb-2">

                <template v-for="(error, name) in errors" :key="name">
                    <template v-for="(message, idx) in error" :key="idx">
                        <notify-danger class="mt-2">{{ message }}</notify-danger>
                    </template>
                </template>
            </div>
        </div>
        <div class="col-lg-12 button-block">
            <div>
                <main-button @click="register" :isActive="SubmitIsActive" :buttonDisabled="buttonDisabled"
                >Зарегистрироваться
                </main-button
                >
            </div>
            <div class="agree-block">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="agree-checkbox"
                    v-model="checkBox"
                    @click="buttonDisabled = !buttonDisabled"
                />
                <label class="form-check-label" for="agree-checkbox"
                >С условиями <router-link to="/rules/" target="_blank">акции</router-link> ознакомлен</label
                >
            </div>
        </div>
    </div>

    <transition name="modal">
        <modal v-if="showModal" @close="showModal = false">

            <modal-header>Информация отправлена</modal-header>

            <modal-body-center>
                <div>
                    <div>
                        <font-awesome-icon icon="info-circle" class="icon alt info-icon"/>
                    </div>
                    <div>
                        {{successMessage}}
                    </div>
                </div>
            </modal-body-center>

            <modal-footer-center>
                <modal-secondary-button @click="showModal = false">Назад</modal-secondary-button>
                <modal-main-button @click="$router.push({ name: 'Login' })">Вход
                    <font-awesome-icon icon="long-arrow-alt-right" class="ml-1 icon alt"/>
                </modal-main-button>
            </modal-footer-center>

        </modal>
    </transition>
</template>

<script>
export default {
    name: "Registration",
    data() {
        return {
            isLoadSuggestions: false,
            compamy: '',
            suggestion: '',
            user: {
                phone: "",
                email: "",
                surname: "",
                name: "",
                patronymic: "",
                gender: "Мужской",
                dateOfBirth: new Date().toISOString().slice(0, 10),
                country: "",
                town: "",
                shopAddress: "",
                shopName: "",
                organization: "",
                file: "",
                tradespot: "",
            },
            customInputPhone: {
                type: "text",
                name: "phone",
                text: "Номер телефона",
            },
            customInputEmail: {
                type: "email",
                name: "email",
                text: "E-mail",
            },
            customInputSurname: {
                type: "text",
                name: "surname",
                text: "Фамилия",
            },
            customInputName: {
                type: "text",
                name: "name",
                text: "Имя",
            },
            customInputPatronymic: {
                type: "text",
                name: "patronymic",
                text: "Отчество",
            },
            selected: "",
            customInputDateOfBirth: {
                type: "date",
                name: "date-of-birth",
                placeholder: "",
                text: "Дата рождения",
            },
            customInputCompany: {
                type: "text",
                name: "inn",
                text: "ИНН",
            },
            uploadFileText: "Загрузить файл",
            buttonDisabled: true,
            SubmitIsActive: false,
            errors: [],
            checkBox: false,
            showModal: false,
            successMessage: "",
            timer: 0,
            check: false,
        };
    },
    computed: {
        suggestions() {
            return this.$store.getters.getSuggestions;
        },
        tradespots() {
            return this.$store.getters.getTradespots;
        },
        errorsCompany() {
            return this.$store.getters.getErrorsCompany;
        },
    },
    methods: {
        setUserData(name, value) {
            if (value instanceof Event) return;

            this.user[name] = value;
        },
        resetData() {
            this.user = {
                phone: "",
                email: "",
                surname: "",
                name: "",
                patronymic: "",
                gender: "Мужской",
                dateOfBirth: new Date().toISOString().slice(0, 10),
                country: "",
                town: "",
                shopAddress: "",
                shopName: "",
                organization: "",
                file: "",
                tradespot: "",
            };
            
            this.check = false;
            this.compamy = '';
            this.suggestion = '';
            
            this.SubmitIsActive = false

            this.$store.dispatch("companyReset")
        },
        changeCompamy(value) {
            if (value instanceof Event) return;

             var timer = setTimeout(() => {
                 
                if (this.timer == timer) {
                    
                    if (value.length > 5)
                    {
                        this.isLoadSuggestions = true;

                        this.$store
                            .dispatch("companySuggestions", value)
                                .then((isLastRequet) => {
                                
                                if (isLastRequet) {
                                    if (this.isLoadSuggestions)
                                        this.isLoadSuggestions = false;

                                    this.compamy = value;
                                    this.suggestion = '';
                                    this.user.tradespot = '';
                                }
                            });
                    } 
                }

            }, 500);

            this.timer = timer;            
        },
        suggestionsChange(value) {

            if (value instanceof Event) return;

            if (!this.isLoadSuggestions)
                this.isLoadSuggestions = true;

             this.$store
                .dispatch("companyTradespots", {
                    'company': value,
                }).then((isLastRequet)=>{

                    if (isLastRequet) {

                        let select = this.suggestions.filter((item)=>{
                            return item.value == value
                        });

                        this.user.organization = select[0].name;

                        this.suggestion = value;
                        this.user.tradespot = '';

                        if (this.isLoadSuggestions)
                            this.isLoadSuggestions = false; 
                    }
                });

            
        },
        chooseFiles() {
            document.getElementById("fileUpload").click();
        },
        previewFiles() {
            this.user.file = this.$refs.uploadFile.files[0];
            this.uploadFileText = this.$refs.uploadFile.files[0].name;
        },
        checkForm() {

            this.check = true;
            
            if (this.user.phone.length <= 0)
            {
                return false;
            }

            if (this.user.email.length <= 0)
            {
                return false;
            }

            if (this.user.surname.length <= 0)
            {
                return false;
            }

            if (this.user.name.length <= 0)
            {
                return false;
            }

            if (this.user.gender.length <= 0)
            {
                return false;
            }

            if (this.user.dateOfBirth.length <= 0)
            {
                return false;
            }

            if (this.user.tradespot.length <= 0)
            {
                return false;
            }

            if (this.compamy.length <= 0)
            {
                return false;
            }

            if (this.suggestion.length <= 0)
            {
                return false;
            }
            
            return true;
        },  
        register() {

            if (!this.checkForm())
            {
                return false;
            }
            
            this.SubmitIsActive = true
            this.errors = [];
            
            this.$store
                .dispatch("registerUsers", this.user)
                .then((response) => {
                  console.log(response);
                    if (response) {
                        this.successMessage = response.data.message
                        this.checkBox = false;
                        this.buttonDisabled = true;
                        this.SubmitIsActive = false;
                        this.showModal = true;
                        this.uploadFileText = "Загрузить файл";
                        this.resetData();
                    } else {
                        this.SubmitIsActive = false;
                        this.checkBox = false;
                        this.buttonDisabled = true;
                    }
                })
                .catch((reason) => {
                    var errorMessage;

                    if (reason.response.data.errors) {
                        errorMessage = reason.response.data.errors;
                    } else if (reason.response.data.message) {
                        errorMessage = [[reason.response.data.message]]
                    } else if (reason.request) {
                        errorMessage = [[reason.request]]
                    } else {
                        errorMessage = [[reason.message]]
                    }

                    if (errorMessage) {
                        this.SubmitIsActive = false;
                        this.checkBox = false;
                        this.buttonDisabled = true;
                        this.errors = errorMessage;
                    }
                });
        },
    },
};
</script>

<style scoped>
.upload-file-field {
    display: flex;
    align-items: center;
    padding-top: 5px;
}

.upload-file-icon {
    color: #71747a;
}

.right-arrow-icon {
    padding-left: 10px;
}

.info-icon {
    color: #3663F2;
    font-size: 30px;
    margin-bottom: 20px;
}

.auth-block {
    color: #71747a;
}

.auth-block a {
    color: #71747a;
    display: flex;
    align-items: center;
}

.auth-block a:hover {
    text-decoration: none;
    color: #3663f2;
}

.custom-input-with-text {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #f2f3f4;
    /* width: 350px; */
    padding: 10px 15px;
}

.custom-input-with-text-text {
    font-size: 13px;
    color: #71747a;
}

.file-upload {
    cursor: pointer;
}

.file-upload-text {
    color: #71747a;
}

.button-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.agree-block {
    margin-top: 30px;
}

.result {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 768px) {
    .custom-input-with-text {
        width: 100%;
    }

    .block-center {
        max-width: 350px;
        margin: 0 auto;
    }

    .auth-block {
        text-align: center;
    }
}


.custom-select-control {
    font-size: 14px;
    display: block;
    width: 100%;
    border-radius: 8px;
    background-color: #f2f3f4;
    border: none;
    height: 54px;
    margin-top: 15px;
}
</style>
